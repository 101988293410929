import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'
import './styles/Wrapper.scss'
import 'semantic-ui-css/semantic.min.css'

class App extends Component {
  render() {
    let emailTo = 'unito@unito.com.br'
    let emailSub = 'Website inquiry'

    return (
      <div className="wrapper">
        <div className="headerContainer" />
        <div className="mainContainer">
          <div>
            <img src={require('./images/logoUnito2012.png')} />
          </div>
          <div className="homeColumns">
            <div className="projects">
              <h1>Projects</h1>
              We create original projects for community apps and IoT smart home devices.
            </div>
            <div className="consulting">
              <h1>Consulting</h1>
              Software and IT infrastructure planning.
            </div>
            <div className="development">
              <h1>Development</h1>
              We bring ideas to life, from planning and architecture
              to cloud deployment.
            </div>
          </div>
          <div className="footer">
            <Button
              color="blue"
              size="large"
              onClick={() => {
                location.href = "mailto:"+emailTo+"?subject="+emailSub
              }}
            >
              Contact Us</Button>
          </div>
        </div>
      </div>
    )
  }
}

export default App